import React, { Component } from 'react';
import {Grid} from '@material-ui/core';
import {Box} from '@material-ui/core';
import {makeStyles, Link} from '@material-ui/core'
import './acerca.css'

const Acerca = (props) => {
    return(
        <div>
            <div className = "acerca-div"></div>
            <Grid container>
                <Grid item sm = {1}></Grid>
                <Grid item sm = {5}>
                    <b className = "titulo-acerca">Concepto del juego</b>
                    
                    <div className = "creditos1">
                        Alexander Caicedo<br/>
                        Juan Fernando Pérez<br/>
                        Rafael Méndez<br/>
                        Valérie Gauthier<br/>
                    </div>

                    <br></br>    
                    <b className = "titulo-acerca">Diseño del juego</b>
                    
                    <div className = "creditos1">
                        Alexander Caicedo<br/>
                        Juan Fernando Pérez<br/>
                        Valérie Gauthier<br/>
                    </div>

                    <br></br>    
                    <b className = "titulo-acerca">Desarrollo</b>
                    
                    <div className = "creditos1">
                    Dora Suárez<br/>
                    Jorge Hernández<br/>
                    Miguel Orjuela<br/>
                    </div>

                    <br></br>    
                    <b className = "titulo-acerca">Concepto de arte e ilustraciones</b>
                    
                    <div className = "creditos1">
                    Alexander Caicedo<br/>
                    Dora Suárez<br/>
                    Juan Fernando Pérez<br/>
                    Miguel Orjuela<br/>
                    Valérie Gauthier<br/>
                    </div>
                </Grid>
                <Grid item sm = {5} xs = {10}>
                    <b className = "titulo-acerca">Voces</b>
                    
                    <div className = "creditos1">
                    Christian Gordillo<br/>
                    Natalia Ballesteros<br/>
                    </div>

                    <br></br>    
                    <b className = "titulo-acerca">Historia</b>
                    
                    <div className = "creditos1">
                    Alexander Caicedo<br/>
                    Juan Fernando Pérez<br/>
                    Valérie Gauthier<br/>
                    </div>

                    <br></br>    
                    <b className = "titulo-acerca">Desarrollo de contenido</b>
                    
                    <div className = "creditos1">
                    Nicolás Correa<br/>
                    Christian Gordillo<br/>
                    Yeison Herrera<br/>
                    Ángela Gaitán<br/>
                    Andrés Ramírez<br/>
                    Alexander Caicedo<br/>
                    Valérie Gauthier<br/>
                    Juan Fernando Pérez<br/>
                    </div>
                </Grid>
                <Grid item sm = {1}></Grid>

            </Grid>

<br></br> 
<br></br> 
<br></br> 
    
            <br></br>    


        </div>
    );
};

export default Acerca;