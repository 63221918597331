import React, { Component } from 'react';
import {Grid} from '@material-ui/core';
import {Box} from '@material-ui/core';
import {makeStyles, Link} from '@material-ui/core'

import './h_min.css'

const useStyles = makeStyles((theme) => ({
    item1: {
      order: 1,
      [theme.breakpoints.up('md')]: {
        order: 2,
        padding: "20px"
      },
    },
    item2: {
      order: 2,
      [theme.breakpoints.up('md')]: {
        order: 1,
        padding: "20px"
      },
    },
  }));

const HistoriaCuadro = (props) => {

    const classes = useStyles();

    let salida = []

    if(props.ub == "left"){
        salida.push(
        <Grid container justify="flex-start" className = "texto-historia">
        <Grid md = {4} className = {classes.item1}>
            {props.texto}
        </Grid>

        <Grid md = {6} className = {classes.item2}>
            <img width="100%" src = {"assets/historia/" + props.nameImage + ".png" } className = "imagen-historia"></img>
        </Grid>
    </Grid>)
    }else{
        salida.push(<Grid container justify="flex-start" className = "texto-historia">
        <Grid md = {4} className = {classes.item1}>
            {props.texto}
        </Grid>

        <Grid md = {6} className = {classes.item1}>
            <img width="100%" src = {"assets/historia/" + props.nameImage + ".png"}></img>
        </Grid>
        </Grid>)
    }

    console.log(salida)

    return(
        <div>
            {salida}           
        </div>
    );
};

export default HistoriaCuadro;