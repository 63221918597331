import React from 'react';
import ReactPlayer from "react-player"
import {Grid} from '@material-ui/core';

import "../home/home.css"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import VideoCarr from "./components/carrousel"
import Historia from "./components/historia/historia"
import Acerca from "./components/acerca/acerca"
import TabsHis from "./components/tabs/tabs"

const HomeVideos = (props) => {
    return(
        <div>
            <div>
                <Grid container>
                    <Grid item sm = {6} xs = {12} md = {3}>
                        <img width="100%" src = "assets/URdigital.png" className = "logourdigital"></img>
                    </Grid>
                    <Grid item sm = {6} xs = {12} md = {6}></Grid>

                    <Grid item xs = {1} sm = {3} md = {4} lg = {4}></Grid>
                    <Grid item sm = {6} xs = {10} md = {4} lg = {4}>
                        <img width="100%" src = "assets/LogoFinal.png"></img>
                    </Grid>
                    <Grid item sm = {1} sm = {3} md = {4} lg = {4}></Grid>
                </Grid>

                <Grid container>
                    <Grid item sm = {2}></Grid>
                    <Grid item sm = {8}>
                        <div className="descripcion">
                        En esta página podrás encontrar información sobre la app Turo de URosario, una aplicación diseñada para apoyar el aprendizaje de inglés. Descárgala y empieza la aventura.
                        </div>
                    </Grid>
                    <Grid item sm = {2}></Grid>
                </Grid>

                <Grid container>
                    <Grid sm = {4}></Grid>
                    <Grid sm = {2} item className = "avaliable">
                        <a href = "https://apps.apple.com/co/app/turo-urosario/id1539340612?l=en">
                            <img width="100%" src = "assets/apple.png"></img>
                        </a>
                    </Grid>
                    <Grid sm = {2} item className = "avaliable">
                        <a href = "https://play.google.com/store/apps/details?id=com.URosario.Babeltech">
                            <img width="100%" src = "assets/playstore.png"></img>
                        </a>
                    </Grid>
                    <Grid sm = {4}></Grid>
                </Grid>
            </div>


            <TabsHis className = "tabs-completo"></TabsHis>


        </div>
    )
}

export default HomeVideos;