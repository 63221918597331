import React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import VideoCarr from "../carrousel"
import Historia from "../historia/historia"
import Acerca from "../acerca/acerca"

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }
  
  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.background.paper,
      width: "100%",
    },
  }));
  
  export default function TabsHis() {

    const videoUrls =[
      "https://youtu.be/-EyVSi87WyI", //1
      "https://youtu.be/l48gSYb6Q_c", //2
      "https://youtu.be/g6Gn7GLKhC4", //3
      "https://youtu.be/BCYZqik_sc8", //4
      "https://youtu.be/NH8ZAuN_Ub8", //5
      "https://youtu.be/_wnM8AhHJqU", //6
      "https://youtu.be/B4_rVPdg0UM", //7 
      "https://youtu.be/qmMBElFROtU", //8 
      "https://youtu.be/Ld69SbAdilc", //9
      "https://youtu.be/DfULQsQdQxE", //10
      "https://youtu.be/1nptbwt8jqQ ", //11
      "https://youtu.be/H9Lxry-60jc", //12
      "https://youtu.be/dMEybFwyFrA", //13
      "https://youtu.be/67DyQptHn4s" //14
  ]

  const videoJournal =[
    "https://youtu.be/_MbCXt3Iijg", //15
    "https://youtu.be/t3h40IrUnBE",// 16
    "https://youtu.be/Nm7rn9F5brg", // 17
    "https://youtu.be/MofCQ85UgkU", // 18
    "https://youtu.be/jGKtirI7PxA", // 19
    "https://youtu.be/RxiILlUUIok", // 20 
    "https://youtu.be/YQTl_ictI3Y", // 21 
    "https://youtu.be/FNENVPD9Lxw" // 22 
]

    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = React.useState(0);
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  
    const handleChangeIndex = (index) => {
      setValue(index);
    };
  
    return (
      <div className={classes.root}>
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab label="Historia" icon= {<img width="40%" src = "assets/icons/sageIcon.png" className = "icon"></img>} {...a11yProps(0)} />
            <Tab label="¿Cómo jugar?" icon= {<img width="40%" src = "assets/icons/comojugar.png" className = "icon"></img>} {...a11yProps(1)} />
            <Tab label="Bitácora" icon= {<img width="38%" src = "assets/icons/libro.png" className = "icon"></img>} {...a11yProps(2)} />
            <Tab label="Acerca de Turo" icon= {<img width="35%" src = "assets/icons/abouticon.png" className = "icon"></img>} {...a11yProps(3)} />
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel value={value} index={0} dir={theme.direction}>
              <Historia></Historia>
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <VideoCarr videos = {videoUrls} pref = "min"></VideoCarr>
          </TabPanel>
          <TabPanel value={value} index={2} dir={theme.direction}>
            <VideoCarr videos = {videoJournal} pref = "jour"></VideoCarr>
          </TabPanel>
          <TabPanel value={value} index={3} dir={theme.direction}>
             <Acerca></Acerca>
          </TabPanel>
        </SwipeableViews>
      </div>
    );
  }