import {React, useState, useEffect} from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../components/carrousel.css";

import ReactPlayer from "react-player"
import { Grid } from '@material-ui/core';

  const VideoCarr = (props) => {

    const [url, setUrl] = useState(props.videos[0]);

    var videos = document.querySelectorAll(".miniaturas")

    const imagenes = []
    for(let i = 0; i < props.videos.length; i++){
        imagenes.push(
            <Grid md = {12} sm = {4} xs = {6}>
                <button className = "miniatura" id = {i} onClick = {() => setUrl(props.videos[i])}>
                    <img src = {"assets/miniaturas/" + props.pref + (i+1) + ".png"} width = "95%"></img>
                </button>
            </Grid>
        )
    }



    return(
        <Grid container>
            <Grid item md = {7} xs = {12}>
                <div className = "video-container">
                    <ReactPlayer url= {url} width = "100%" height = "100%"/>
                </div>
            </Grid>
            <Grid item md = {1}></Grid>
            <Grid container className = "caja-imagenes" md = {3} xs = {12}>
                {imagenes}
            </Grid>
            <Grid item sm = {1}>
            </Grid>
        </Grid>
    );
};

export default VideoCarr;