import React, { Component } from 'react';
import {Grid} from '@material-ui/core';
import {Box} from '@material-ui/core';
import {makeStyles, Link} from '@material-ui/core'

import HistoriaCuadro from '../historia_simple/h_min'
import './historia.css'

const Historia = (props) => {

    let textos = [
        "Hace mucho, mucho tiempo, en los albores de la humanidad, siete sabios descendieron a la tierra para conceder a los hombres el regalo divino del lenguaje.",
        "Ellos unificaron las comunidades primitivas por medio de un único lenguaje. Éste fue un periodo de paz y prosperidad donde la humanidad creció y se desarrolló. Sin embargo, debido a la distancia geográfica, la ambición, y la necesidad de guardar secretos de los humanos, el lenguaje original comenzó a cambiar y convertirse en las diferentes formas que conocemos hoy en día.",
        "Para traer un poco de orden, los sabios construyeron varias torres, y en cada una almacenaron los secretos y la estructura de un lenguaje particular. Sin embargo, surgió la guerra y el lenguaje es una fuente de poder para quien le controle.",
        "Para resguardar este conocimiento y evitar que cayese en manos equivocadas, los siete sabios y las torres se esfumaron de nuestra realidad, junto con nuestra memoria sobre ellos, y así se perdieron en la historia.",
        "Milenios, siglos, décadas, años, y días han pasado desde su desaparición. Pero una vez más, los siete sabios han decidido otorgarle una nueva oportunidad a la humanidad, y probar que el conocimiento sobre el Inglés puede ser utilizado para hacer el bien.",
        "Ellos han escogido a ciertas personas para obtener las siete llaves, y descubrir los secretos ocultos en la torre ¡Tú eres uno de ellos! Es tu misión sobrepasar todos los retos que la torre te presente, encontrar las llaves y descubrir sus secretos.",
        "Si demuestras ser digno, lograrás dominar el idioma Inglés, y de esta forma traer paz y prosperidad al mundo una vez más.",
    ]

    let salida_compelta = []
    let ub = ""
    for(let i = 0; i < 7; i++){
        
        if(i%2 == 0){
            ub = "left"
        }else{
            ub = "rigth"
        }

        salida_compelta.push(<HistoriaCuadro texto = {textos[i]} nameImage = {"hh" + (i+1)} ub = {ub}></HistoriaCuadro>)
    }

    return(
        <div className = "root-historia">
            {salida_compelta}
        </div>
    );
};

export default Historia;