import logo from './logo.svg';
import './App.css';
import {BrowserRouter as Router, Route, Switch, Redirect} from "react-router-dom";
import HomeVideos from "./pages/home/home"

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path = "/home" component = {HomeVideos}></Route>
        <Route exact path = "/" component = {HomeVideos}></Route>
      </Switch>
    </Router>
  );
}

export default App;
